import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import './Page.css'; // Import the CSS file

function Records() {
  const navigate = useNavigate();
  const { signOut } = useAuthenticator();

  const handleBack = () => {
    navigate('/');
  };

  const handleSignOut = async () => {
    await signOut();
    navigate('/login');
  };

  return (
    <div className="page-container">
      <h2 className="page-h2">Records</h2>
      <div className="page-buttons">
        <button onClick={handleBack}>Back</button>
        <button onClick={handleSignOut}>Sign out</button>
      </div>
      <p className="page-text">More to come soon!</p>
    </div>
  );
}

export default Records;
