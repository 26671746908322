import React, { useEffect } from 'react';
import { Authenticator, CheckboxField, TextField } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import './Auth.css';
import { useAuthenticator } from '@aws-amplify/ui-react';

function Login() {
  const navigate = useNavigate();
  const { user } = useAuthenticator((context) => [context.user]);

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user, navigate]);

  const handleSubmit = (event) => {
    // Handle any additional logic if necessary
    // Avoid setting state here
  };

  return (
    <div className="auth-container">
      <Authenticator
        components={{
          SignUp: {
            FormFields() {
              return (
                <>
                  <TextField
                    name="email"
                    label="Email"
                    placeholder="Enter your email"
                    type="email"
                    required
                  />
                  <TextField
                    name="password"
                    label="Password"
                    placeholder="Enter your password"
                    type="password"
                    required
                  />
                  <TextField
                    name="confirm_password"
                    label="Confirm Password"
                    placeholder="Confirm your password"
                    type="password"
                    required
                  />
                  <CheckboxField
                    className="terms-checkbox"
                    label={
                      <>
                        You agree to the following&nbsp;
                        <a href="/terms-and-conditions.pdf" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>
                        &nbsp;and&nbsp;
                        <a href="/privacy-policy.pdf" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                      </>
                    }
                    name="terms"
                    required
                  />
                </>
              );
            },
          },
        }}
        onSubmit={handleSubmit}
      >
        {/* You can customize the children if needed */}
      </Authenticator>
    </div>
  );
}

export default Login;
