import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';

function PrivateRoute({ children }) {
  const { user } = useAuthenticator(context => [context.user]);

  return user ? children : <Navigate to="/login" replace />;
}

export default PrivateRoute;
