import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import './Page.css'; // Import the CSS file

function Tutorials() {
  const navigate = useNavigate();
  const { signOut } = useAuthenticator();

  const handleBack = () => {
    navigate('/');
  };

  const handleSignOut = async () => {
    await signOut();
    navigate('/login');
  };

  return (
    <div className="page-container">
      <h2 className="page-h2">Tutorials</h2>
      <div className="page-buttons">
        <button onClick={handleBack}>Back</button>
        <button onClick={handleSignOut}>Sign out</button>
      </div>
      <p className="page-text">BetPlus software identifies profitable sports wagers that yield long-term net gains. To ensure success and maximize returns, your adherence to specific guidelines - from creating sportsbook accounts to maintaining sufficient funds - is essential. Please read through the below tutorials to learn these guidelines and begin your BetPlus experience!</p>
      
      {/* New Links Section */}
      <div className="centered-links">
        <div>
          <span>Read this before starting: </span>
          <a href="/tutorials/overview.pdf" target="_blank" rel="noopener noreferrer" className="link-spacing">Overview</a>
        </div>
        <div>
          <span>MUST KNOW THESE RULES: </span>
          <a href="/tutorials/critical-rules.pdf" target="_blank" rel="noopener noreferrer" className="link-spacing">Critical Rules</a>
        </div>
      </div>

      <div className="pdf-links-container">
        <div className="pdf-column">
          <p className="pdf-header">1. BetPlus: Getting Started</p>
          <a href="/tutorials/creating-accounts.pdf" target="_blank" rel="noopener noreferrer">1.1 Creating Accounts</a>
          <a href="/tutorials/depositing-to-accounts.pdf" target="_blank" rel="noopener noreferrer">1.2 Depositing to Accounts</a>
          <a href="/tutorials/withdrawing-from-accounts.pdf" target="_blank" rel="noopener noreferrer">1.3 Withdrawing from Accounts</a>
          <p className="pdf-header">2. BetPlus: Account Management</p>
          <a href="/tutorials/accessing-your-accounts.pdf" target="_blank" rel="noopener noreferrer">2.1 Accessing Your Accounts</a>
          <a href="/tutorials/maintaining-account-balances.pdf" target="_blank" rel="noopener noreferrer">2.2 Maintaining Account Balances</a>
          <a href="/tutorials/checking-for-promos.pdf" target="_blank" rel="noopener noreferrer">2.3 Checking for Promos</a>
          <a href="/tutorials/billing.pdf" target="_blank" rel="noopener noreferrer">2.4 Billing</a>
          <a href="/tutorials/taxes.pdf" target="_blank" rel="noopener noreferrer">2.5 Taxes</a>
        </div>
        <div className="pdf-column">
          <p className="pdf-header">3. Sportsbook Promos: Essential Knowledge</p>
          <a href="/tutorials/promos-overview.pdf" target="_blank" rel="noopener noreferrer">3.1 Promos Overview</a>
          <a href="/tutorials/sports-betting-101.pdf" target="_blank" rel="noopener noreferrer">3.2 Sports Betting 101</a>
          <a href="/tutorials/betting-markets.pdf" target="_blank" rel="noopener noreferrer">3.3 Betting Markets</a>
          <a href="/tutorials/hedging-bets.pdf" target="_blank" rel="noopener noreferrer">3.4 Hedging Bets</a>
          <a href="/tutorials/qualifying-bets.pdf" target="_blank" rel="noopener noreferrer">3.5 Qualifying Bets</a>
          <p className="pdf-header">4. Sportsbook Promos: Placing Your First Bets</p>
          <a href="/tutorials/sign-up-bonus-gameplan.pdf" target="_blank" rel="noopener noreferrer">4.1 Sign-Up Bonus Gameplan</a>
          <a href="/tutorials/second-chance-bets.pdf" target="_blank" rel="noopener noreferrer">4.2 Second Chance Bets</a>
          <a href="/tutorials/additional-promos.pdf" target="_blank" rel="noopener noreferrer">4.3 Additional Promos</a>
        </div>
      </div>
    </div>
  );
}

export default Tutorials;
