import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useUser } from './UserContext';
import './Home.css'; // Import the CSS file

function Home() {
  const navigate = useNavigate();
  const { signOut } = useAuthenticator((context) => [context.user]);
  const { userAttributes, loading } = useUser();

  const handleSignOut = async () => {
    await signOut();
    navigate('/login');
  };

  if (loading) {
    return <h1>Loading...</h1>; // Show loading state while fetching user attributes
  }

  return (
    <div className="home-container">
      <h1>BETPLUS</h1>
      <h2>Welcome, {userAttributes.name}!</h2>
      <button className="sign-out-button" onClick={handleSignOut}>Sign out</button>
      <nav>
        <ul className="nav-links">
          <li><Link to="/account-manager">Account Manager</Link></li>
          <li><Link to="/records">Records</Link></li>
          <li><Link to="/tutorials">Tutorials</Link></li>
        </ul>
      </nav>
    </div>
  );
}

export default Home;
