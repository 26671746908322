import React, { createContext, useContext, useState, useEffect } from 'react';
import { getCurrentUser, fetchUserAttributes } from '@aws-amplify/auth';
import { useAuthenticator } from '@aws-amplify/ui-react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const { user } = useAuthenticator(); // Get the current user from the authenticator
  const [userAttributes, setUserAttributes] = useState({ id: '', state: '', name: '' });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initializeUser = async () => {
      if (!user) {
        setLoading(false); // If no user, set loading to false and return
        return;
      }

      try {
        const currentUser = await getCurrentUser();
        if (currentUser) {
          const attributes = await fetchUserAttributes(currentUser);
          setUserAttributes({
            id: attributes['custom:id'] || '',
            state: attributes['custom:state'] || '',
            name: attributes['custom:name'] || 'User',
          });
        }
      } catch (error) {
        console.error('Error fetching user attributes:', error);
      } finally {
        setLoading(false);
      }
    };

    initializeUser();
  }, [user]); // Dependency on user to re-run effect when user changes

  return (
    <UserContext.Provider value={{ userAttributes, loading }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);